<template>
  <b-form-group :label="label">
    <b-form-radio-group
      :id="id"
      v-model="val"
      :options="options"
      :name="name"
      :text-field="textField"
      :value-field="valueField"
      @change="onDataChange"
      stacked
    ></b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    id: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    name: {
      required: true,
      type: String,
    },
    textField: {
      required: true,
      type: String,
    },
    valueField: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: String | Number,
    },
  },
  data() {
    return {
      val : this.value
    };
  },
  methods: {
    onDataChange(evt) {
      this.$emit("onDataChange", evt);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .col-form-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
</style>