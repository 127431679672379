<template>
  <b-tabs fill v-model="tabIndex">
    <b-tab title="Purchase History" active @click="linkClass(0)">
      <TableListHistory
        :fields="fieldsPurchase"
        :items="itemsPurchase"
        :rows="rowsPurchase"
        :isBusy="isBusyPurchase"
        :filter="filterPurchaseHistory"
        :pageOptions="pageOptions"
        :showing="showingPurchase"
        :showingTo="showingToPurchase"
        @page="setPagePurchase"
        :tab="0"
        @handleChangeTake="handleChangeTake"
      />
    </b-tab>
    <b-tab title="Reward Point History" @click="linkClass(1)">
      <TableListHistory
        :fields="fieldsPoint"
        :items="itemsPoint"
        :rows="rowsPoint"
        :isBusy="isBusyPoint"
        :filter="filterPointHistory"
        :pageOptions="pageOptions"
        :showing="showingPoint"
        :showingTo="showingToPoint"
        :tab="1"
        @handleChangeTake="handleChangeTake"
        @page="setPagePurchase"
      />
    </b-tab>
    <b-tab title="Privilege history" @click="linkClass(2)">
      <TableCouponList
        :fields="fieldCoupon"
        :items="itemsCoupon"
        :rows="rowsCoupon"
        :isBusy="isBusyPoint"
        :filter="filterPointHistory"
        :pageOptions="pageOptions"
        :showing="showingPoint"
        :showingTo="showingToPoint"
        :tab="3"
        @handleChangeTake="handleChangeTake"
        @page="setPagePurchase"
      />
    </b-tab>
    <b-tab title="Record" @click="linkClass(3)">
      <TabNoteDetail
        :noteList="noteList"
        @updateNote="updateNote"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import TableListHistory from "@/components/report/customer/detail/TableListHistory";
import TableCouponList from "@/components/report/customer/detail/TableCouponList";
import TabNoteDetail from "@/components/report/customer/detail/TabNoteDetail";
export default {
  components: {
    TableListHistory,
    TabNoteDetail,
    TableCouponList
  },
  props: {
    fieldsPurchase: {
      required: true,
      type: Array,
    },
    itemsPurchase: {
      required: true,
      type: Array,
    },
    rowsPurchase: {
      required: true,
      type: Number,
    },
    isBusyPurchase: {
      required: true,
      type: Boolean,
    },
    filterPurchaseHistory: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showingPurchase: {
      required: true,
      type: Number,
    },
    showingToPurchase: {
      required: true,
      type: Number,
    },
    fieldsPoint: {
      required: true,
      type: Array,
    },
    itemsPoint: {
      required: true,
      type: Array,
    },
    rowsPoint: {
      required: true,
      type: Number,
    },
    isBusyPoint: {
      required: true,
      type: Boolean,
    },
    filterPointHistory: {
      required: true,
      type: Object,
    },
    showingPoint: {
      required: true,
      type: Number,
    },
    showingToPoint: {
      required: true,
      type: Number,
    },
    noteList: {
      required: true,
      type: Object,
    },
    fieldCoupon:{
      required: true,
      type: Array,
    },
    itemsCoupon:{
      required: true,
      type: Array,
    },
    rowsCoupon:{
      required: true,
      type: Number,
    }
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    setPagePurchase(filter) {
      this.$emit("setPagePurchase", filter);
    },
    linkClass(value) {
      this.$emit("selectTab", value);
    },
    handleChangeTake(filter) {
      this.$emit("handleChangeTake", filter);
    },
    updateNote(note){
      this.$emit("updateNote",note);
    }
  },
};
</script>

<style scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
</style>
