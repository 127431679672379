<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div>
        <div class="row flex-nowrap wrapper">
          <div class="col pt-2">
            <HeaderCouponList
              :title="'Usage Information'"
              :filter="formCoupon"
              :link="'/report/customer/0'"
              :placeholder="'Search Coupon Name, Coupon Code'"
              :btnFilter="true"
              :btnExport="false"
              @sidebar="sidebarFilterCoupon"
              @filterCoupon="searchFilterCoupon"
              :tab="tabIndex"
              v-if="tabIndex === 2"
            />
            <HeaderMenuReportPanel
              :title="'Usage Information'"
              :filter="filterPurchaseHistory"
              :link="'/report/customer/0'"
              :placeholder="'Search Order Number'"
              :btnFilter="true"
              :btnExport="false"
              @sidebar="sidebarFilterTransaction"
              @search="searchFilter"
              :tab="tabIndex"
              v-else
            />
            <div class="bg-white mt-3">
              <TabHistoryDetail
                :fieldsPurchase="fieldsPurchase"
                :fieldCoupon="fieldCoupon"
                :itemsCoupon="couponList"
                :itemsPurchase="itemsPurchase"
                :rowsPurchase="rowsPurchase"
                :isBusyPurchase="isBusyPurchase"
                :filterPurchaseHistory="filterPurchaseHistory"
                :pageOptions="pageOptions"
                :showingPurchase="showingPurchase"
                :showingToPurchase="showingToPurchase"
                @setPagePurchase="setPagePurchase"
                :fieldsPoint="fieldsPoint"
                :itemsPoint="itemsPoint"
                :rowsPoint="rowsPoint"
                :isBusyPoint="isBusyPoint"
                :filterPointHistory="filterPointHistory"
                :showingPoint="showingPoint"
                :showingToPoint="showingToPoint"
                @selectTab="(val) => (tabIndex = val)"
                @handleChangeTake="handleChangeTake"
                :noteList="noteList"
                @updateNote="updateNote"
                :rowsCoupon="rowsCoupon"
              />
            </div>
          </div>
          <b-button
            @click.prevent="
              showProfile ? (showProfile = false) : (showProfile = true)
            "
            class="tab-right"
          >
            <div class="rotate-text">
              {{ form.first_name_th }} {{ form.last_name_th }}
              <font-awesome-icon icon="caret-up" />
            </div>
          </b-button>
          <div class="w-sidebar pl-0 pr-0" v-if="showProfile">
            <SidebarProfile
              :form="form"
              :memberLevelList="memberLevelList"
              :genderList="genderList"
              @updateAddress="updateProfileDetail"
              @updateProfile="updateProfileDetail"
              :v="$v"
              @openModalPoint="openModalPoint"
              :customerQuestionList="customerQuestionList"
              @updatePersonal="updatePersonal"
              :isLoadingPersonal="isLoadingPersonal"
              :isLoadingUpdatePersonal="isLoadingUpdatePersonal"
              :isLoadingUpdateProfile="isLoadingUpdateProfile"
              :isConsentList="isConsentList"
            />
          </div>
        </div>
      </div>
      <FilterReportTransaction
        ref="sidebarFilter"
        :filter="tabIndex == 0 ? filterPurchaseHistory : filterPointHistory"
        :branch="branchList"
        @filterAll="filterAll"
      />
      <FilterReportCoupon
        ref="sidebarFilterCoupons"
        :filter="formCoupon"
        @filterCoupon="filterCoupon"
        :statusFilter="statusFilter"
      />
      <ModalPoint
        ref="modalPoint"
        :id="$route.params.id"
        :loading="LoadingPoint"
        :branchList="branchList"
        :form="formUpdatePoint"
        :point="point"
        @updatePoint="updatePoint"
        :v="$v.formUpdatePoint"
        @tabIndexPoint="tabIndexPoint"
      />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderMenuReportPanel from "@/components/report/header/HeaderMenuReportPanel";
import HeaderCouponList from "@/components/report/header/HeaderCouponList";
import SidebarProfile from "@/components/report/customer/detail/SidebarProfile";
import TabHistoryDetail from "@/components/report/customer/detail/TabHistoryDetail";
import FilterReportTransaction from "@/components/report/customer/detail/FilterReportTransaction";
import FilterReportCoupon from "@/components/report/customer/detail/FilterReportCoupon";
import ModalPoint from "@/components/report/customer/detail/modal/ModalPoint";
import { required, requiredIf, email } from "vuelidate/lib/validators";
export default {
  components: {
    OtherLoading,
    HeaderMenuReportPanel,
    SidebarProfile,
    TabHistoryDetail,
    FilterReportTransaction,
    ModalPoint,
    HeaderCouponList,
    FilterReportCoupon,
  },
  validations: {
    form: {
      first_name_th: { required },
      last_name_th: { required },
      telephone: { required },
      email: { email },
    },
    formUpdatePoint: {
      branch_id: {
        required: requiredIf(function (item) {
          return this.typePoint === 2;
        }),
      },
      update_point: {
        required,
      },
      addPoint: {
        required: requiredIf(function (item) {
          return this.pointTab === 0;
        }),
      },
      deletePoint: {
        required: requiredIf(function (item) {
          return this.pointTab === 1;
        }),
      },
      editPoint: {
        required: requiredIf(function (item) {
          return this.pointTab === 2;
        }),
      },
    },
  },
  data() {
    return {
      formCoupon: {
        user_guid: this.$route.params.id,
        search: "",
        start_date: null,
        end_date: null,
        status: [0],
        page: 1,
        take: 10,
      },
      isLoading: true,
      id: this.$route.params.id,
      filterPurchaseHistory: {
        user_guid: this.$route.params.id,
        search: null,
        page: 1,
        take: 10,
        branch_id: 0,
        start_date: null,
        end_date: null,
      },
      showProfile: true,
      fieldsPurchase: [
        {
          key: "invoice_no",
          label: "Order Number",
        },
        {
          key: "sale_name",
          label: "Sale name",
        },
        {
          key: "status_name",
          label: "Status",
        },
        {
          key: "transaction_date",
          label: "Transaction Date",
        },
        {
          key: "grand_total",
          label: "Grand Total",
        },
        {
          key: "point",
          label: "Point",
        },
      ],
      itemsPurchase: [],
      couponList: [],
      rowsPurchase: 0,
      isBusyPurchase: true,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingPurchase: 1,
      showingToPurchase: 10,
      filterPointHistory: {
        user_guid: this.$route.params.id,
        search: "",
        page: 1,
        take: 10,
        branch_id: 0,
        start_date: null,
        end_date: null,
      },
      fieldsPoint: [
        {
          key: "invoice_no",
          label: "Order number/Code Redeem",
        },
        {
          key: "transaction_date",
          label: "Date/Time",
        },
        {
          key: "grand_total",
          label: "Spending(Baht)",
        },
        {
          key: "point",
          label: "Point",
        },
        {
          key: "current_point",
          label: "Current Point",
        },
        {
          key: "sale_name",
          label: "Sale Name",
        },
        {
          key: "branch_name",
          label: "Branch",
        },
      ],
      fieldCoupon: [
        {
          key: "name",
          label: "Coupon Name",
        },
        {
          key: "start_date",
          label: "Start Date",
        },
        {
          key: "created_time",
          label: "Date/Time Redeem",
        },
        {
          key: "used_date",
          label: "Date/Time Used",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      itemsPoint: [],
      rowsPoint: 0,
      isBusyPoint: true,
      showingPoint: 1,
      showingToPoint: 10,
      tabIndex: 0,
      branchList: [],
      noteList: {},
      form: {
        user_guid: this.$route.params.id,
        first_name_th: null,
        last_name_th: null,
        first_name_en: null,
        last_name_en: null,
        nickname: "",
        email: "",
        telephone: "",
        member_level_id: 0,
        picture: null,
        birthday: this.birthdayCustomer,
        line_id: null,
        line_ref: null,
        home_address: null,
        gender: "",
        zip_code: null,
        province: null,
        town: null,
        alley: null,
        road: null,
        subdistrict: null,
        district: null,
        is_consent: 0,
      },
      memberLevelList: [],
      genderList: [],
      formUpdatePoint: {
        user_guid: this.$route.params.id,
        branch_id: null,
        update_point: 0,
        update_by_user_guid: this.$route.params.id,
        update_type: 0,
        note: null,
        addPoint: null,
        deletePoint: null,
        editPoint: null,
      },
      point: {},
      typePoint: 0,
      pointTab: 0,
      LoadingPoint: false,
      customerQuestionList: [],
      formPersonalField: {
        user_guid: this.$route.params.id,
        admin_user_guid: "",
        question_list: [],
      },
      isLoadingPersonal: true,
      rowsCoupon: 0,
      isLoadingUpdatePersonal: false,
      isLoadingUpdateProfile: false,
      isConsentList: [
        { id: 1, name: "ยินยอม" },
        { id: 2, name: "รอการยินยอม" },
        { id: 0, name: "ไม่ยินยอม" },
        { id: 3, name: "Fail Consent 1" },
        { id: 4, name: "Fail Consent 2" },
        { id: 5, name: "Fail Consent 3" },
        { id: 6, name: "ถอดถอน" },
        { id: 7, name: "ระงับ" },
      ],
      statusFilter: [
        { id: 0, name: "ยกเลิก" },
        { id: 1, name: "ยังไม่ได้ใช้งาน" },
        { id: 2, name: "ใช้งานแล้ว" },
        { id: 3, name: "หมดอายุ" },
      ],
    };
  },
  async created() {
    await this.getCustomerDetail();
    await this.getCustomerPurchaseHistory();
    await this.getCustomerPointHistory();
    await this.getBranchByFilter();
    await this.getNoteCustomer();
    await this.getMemberLevelList();
    await this.getGenderByCustomer();
    await this.getPointByUser();
    await this.getCustomerCustomField();
    await this.getCustomerCoupon(this.formCoupon);
  },
  methods: {
    tabIndexPoint(val) {
      this.pointTab = val;
    },
    async getCustomerCoupon(formCoupon) {
      if (formCoupon.start_date) {
        this.formCoupon.start_date = formCoupon.start_date;
      } else {
        this.formCoupon.start_date = "";
      }
      if (formCoupon.end_date) {
        this.formCoupon.end_date = formCoupon.start_date;
      } else {
        this.formCoupon.end_date = "";
      }
      await this.$store.dispatch("getCustomerCoupon", this.formCoupon);
      const data = this.$store.state.report.stateCustomerCoupon;
      if (data.result === 1) {
        this.rowsCoupon = data.detail.total_count;
        this.couponList = data.detail.detail;
      }
    },
    async getPointByUser() {
      await this.$store.dispatch("getPointByReportCustomer", this.id);
      const data = this.$store.state.report.stateGetPointByReportCustomer;
      if (data.result === 1) {
        this.point = data.detail;
      }
    },
    async updatePoint(val) {
      this.LoadingPoint = true;
      delete val.addPoint;
      delete val.deletePoint;
      delete val.editPoint;
      await this.$store.dispatch("updatePointByReportCustomer", val);
      const data = this.$store.state.report.stateUpdatePointByReportCustomer;
      if (data.result === 1) {
        this.$swal("Update Success!", {
          icon: "success",
        }).then(function () {
          window.location.reload();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
      this.LoadingPoint = false;
    },
    async getCustomerDetail() {
      this.isLoading = true;
      await this.$store.dispatch("getReportCustomerDetail", this.id);
      const data = this.$store.state.report.respCustomerDetail;

      this.form = data;
      let birthDate = this.form.birthday.search("00:00:00");

      if (birthDate === -1) {
        this.form.birthday = this.form.birthday;
      } else {
        this.form.birthday = this.$moment(this.form.birthday).format(
          "YYYY-MM-DDT00:00:00.000+07:00"
        );
      }
    },
    async getCustomerPurchaseHistory() {
      await this.$store.dispatch(
        "getReportPurchaseHistory",
        this.filterPurchaseHistory
      );
      const data = this.$store.state.report.reportPurchaseHistory;
      this.itemsPurchase = data.detail;
      this.rowsPurchase = data.total_count;
      this.isBusyPurchase = false;
    },
    setPagePurchase(filter) {
      if (this.tabIndex === 0) {
        this.filterPurchaseHistory = filter;
        this.getCustomerPurchaseHistory();
      } else if (this.tabIndex === 1) {
        this.filterPointHistory = filter;
        this.getCustomerPointHistory();
      }
    },
    async getCustomerPointHistory() {
      await this.$store.dispatch(
        "getReportPointHistoryList",
        this.filterPointHistory
      );
      const data = this.$store.state.report.reportPointHistoryList;
      this.itemsPoint = data.detail;
      this.rowsPoint = data.total_count;
      this.isBusyPoint = false;
    },
    sidebarFilterTransaction() {
      this.$refs.sidebarFilter.show();
    },
    sidebarFilterCoupon() {
      this.$refs.sidebarFilterCoupons.show();
    },
    async getBranchByFilter() {
      await this.$store.dispatch("getBranchFilterTransaction");
      const data = this.$store.state.report.branchListReport;
      if (data.result == 1) {
        this.branchList = data.detail;
      }
      this.isLoading = false;
    },
    filterAll(value) {
      if (this.tabIndex === 0) {
        this.filterPurchaseHistory = value;
        this.filterPurchaseHistory.start_date = this.filterPurchaseHistory
          .start_date
          ? this.$moment(this.filterPurchaseHistory.start_date).format(
              "YYYY-MM-DD"
            )
          : null;
        this.filterPurchaseHistory.end_date = this.filterPurchaseHistory
          .end_date
          ? this.$moment(this.filterPurchaseHistory.end_date).format(
              "YYYY-MM-DD"
            )
          : null;
        this.getCustomerPurchaseHistory();
      } else if (this.tabIndex === 1) {
        this.filterPointHistory = value;
        this.filterPointHistory.start_date = this.filterPointHistory.start_date
          ? this.$moment(this.filterPointHistory.start_date).format(
              "YYYY-MM-DD"
            )
          : null;
        this.filterPointHistory.end_date = this.filterPointHistory.end_date
          ? this.$moment(this.filterPointHistory.end_date).format("YYYY-MM-DD")
          : null;
        this.getCustomerPointHistory();
      }
    },
    filterCoupon(value) {
      this.formCoupon = value;
      this.getCustomerCoupon(this.formCoupon);
    },
    handleChangeTake(filter) {
      if (this.tabIndex === 0) {
        this.filterPurchaseHistory = filter;
        this.getCustomerPurchaseHistory();
      } else if (this.tabIndex === 1) {
        this.filterPointHistory = filter;
        this.getCustomerPointHistory();
      }
    },
    async getNoteCustomer() {
      await this.$store.dispatch("getNoteReportCustomer", this.id);
      const data = this.$store.state.report.respNote;
      this.noteList = data;
    },
    async updateNote(value) {
      let body = {
        user_guid: this.id,
        note: value,
      };
      await this.$store.dispatch("updateNoteReportCustomer", body);
      const data = this.$store.state.report.updateNoteCustomer;
      if (data.result == 1) {
        this.$swal("Update Success!", {
          icon: "success",
        }).then(function () {
          window.location.reload();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    async getMemberLevelList() {
      await this.$store.dispatch("getMemberLevel");
      let data = this.$store.state.automation.memberLevel;
      if (data.result == 1) {
        this.memberLevelList = data.detail;
      }
    },
    async getGenderByCustomer() {
      await this.$store.dispatch("getGender");
      let data = this.$store.state.automation.genderList;
      if (data.result == 1) {
        for (const gd of data.detail) {
          if (gd.id !== "0") {
            this.genderList.push({
              id: gd.id,
              name: gd.name,
              year: gd.year,
            });
          }
        }
      }
    },
    async updateProfileDetail(form) {
      this.isLoadingUpdateProfile = true;
      await this.$store.dispatch("updateProfileCustomerReport", form);
      const data = this.$store.state.report.updateProfileCustomer;
      if (data.result === 1) {
        this.$swal("Update Success!", {
          icon: "success",
        }).then(function () {
          window.location.reload();
        });
      } else {
        this.isLoadingUpdateProfile = false;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    searchFilter(value) {
      this.filterPurchaseHistory.page = 1;
      this.filterPurchaseHistory.search = value.search;
      this.getCustomerPurchaseHistory();
    },
    searchFilterCoupon(value) {
      this.formCoupon.page = 1;
      this.formCoupon.search = value.search;
      this.formCoupon.start_date = value.start_date;
      this.formCoupon.end_date = value.end_date;
      this.formCoupon.status = value.status;
      this.getCustomerCoupon(this.formCoupon);
    },
    openModalPoint(val) {
      this.typePoint = val;
      this.$refs.modalPoint.show(val);
    },
    async getCustomerCustomField() {
      this.isLoadingPersonal = true;
      await this.$store.dispatch("getCustomerField", this.id);
      const data = this.$store.state.report.stateCustomerField;
      if (data.result === 1) {
        this.customerQuestionList = data.detail;
        this.isLoadingPersonal = false;
      }
    },
    async updatePersonal(value) {
      this.isLoadingUpdatePersonal = true;
      this.formPersonalField.admin_user_guid = this.$cookies.get(
        "back_office_admin_user_guid"
      );
      this.formPersonalField.question_list = value;
      await this.$store.dispatch("updateCustomerField", this.formPersonalField);
      const data = this.$store.state.report.stateUpdateCustomerField;
      if (data.result === 1) {
        this.$swal("Update Success!", {
          icon: "success",
        }).then(function () {
          window.location.reload();
        });
      } else {
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-right {
  right: -176px;
  overflow: hidden;
  width: 2em;
  height: 12em;
  background: #fff;
  border: none;
  box-shadow: 1px 1px 3px grey;
}
.rotate-text {
  transform: rotate(270deg);
  height: 150px;
  width: 133px;
}
.w-sidebar {
  width: 35%;
  background-color: #fff;
}
</style>
